<template>
    <input
        ref="input"
        class="border-gray-300 focus:border-primary placeholder-gray-400 focus:ring focus:ring-light-primary focus:ring-opacity-50 rounded-md shadow-sm"
        :class="[disabled ? 'cursor-not-allowed bg-gray-100' : '']"
        :value="modelValue"
        :disabled="disabled"
        :placeholder="placeholder"
        @input="$emit('update:modelValue', $event.target.value)"
    />
</template>

<script>
    import { defineComponent } from 'vue';

    export default defineComponent({
        props: {
            modelValue: [String, Number],
            disabled: {
                type: Boolean,
                default: false,
            },
            placeholder: {
                type: String,
                default: '',
            },
        },

        emits: ['update:modelValue'],

        methods: {
            focus() {
                this.$refs.input.focus();
            },
        },
    });
</script>
