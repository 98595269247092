<template>
    <div
        class="bg-white flex min-h-[5rem] rounded-l-lg items-center border-l-4 z-10 shadow-lg"
        :class="messageTypeStyle.container"
    >
        <div class="mx-3">
            <XIcon
                v-if="messageTypeStyle.logo === 'error'"
                class="bg-red-600 h-6 w-6 p-1 rounded-full text-white"
                aria-hidden="true"
            />

            <CheckIcon
                v-else-if="messageTypeStyle.logo === 'success'"
                class="bg-green-500 h-6 w-6 p-1 rounded-full text-white"
                aria-hidden="true"
            />

            <exclamation-icon
                v-else
                class="bg-yellow-400 h-6 w-6 p-1 rounded-full text-white"
                aria-hidden="true"
            />
        </div>

        <div>
            <p class="text-sm" :class="messageTypeStyle.text">
                {{ props.message }}
            </p>
        </div>

        <div class="flex-auto mr-5">
            <XIcon
                class="h-6 w-6 float-right cursor-pointer"
                aria-hidden="true"
                @click="emit('remove')"
            />
        </div>
    </div>
</template>

<script setup>
    import { onMounted } from 'vue';
    import { XIcon, CheckIcon, ExclamationIcon } from '@heroicons/vue/solid';

    const props = defineProps({
        message: String,
        type: String,
        duration: {
            type: Number,
            default: 5000,
        },
    });

    const messageTypeStyle = {
        success: {
            container: 'border-green-500',
            logo: 'success',
            text: 'text-green-500',
        },
        error: {
            container: 'border-red-600',
            logo: 'error',
            text: 'text-red-600',
        },
        info: {
            container: 'border-yellow-400',
            logo: 'info',
            text: 'text-yellow-600',
        },
    }[props.type];

    onMounted(() => {
        setTimeout(() => emit('remove'), props.duration);
    });

    const emit = defineEmits(['remove']);
</script>
