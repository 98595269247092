<template>
    <Transition
        enter-from-class="transition duration-500 opacity-0"
        enter-to-class="transition duration-500 opacity-100"
    >
        <div
            v-if="show"
            class="fixed inset-0 flex justify-center"
            style="background-color: rgba(0, 0, 0, 0.6); z-index: 60"
            @click="closeSupportModal()"
        >
            <div
                class="w-full bg-white flex flex flex-row justify-center right-0 absolute h-full max-w-xl overflow-y-auto"
                @click.stop=""
            >
                <div class="absolute top-7 right-10">
                    <XIcon
                        class="h-6 w-6 black cursor-pointer"
                        aria-hidden="true"
                        @click="closeSupportModal()"
                    />
                </div>
                <div>
                    <div class="w-full h-full">
                        <div v-if="formHome" class="h-full">
                            <header
                                class="flex justify-between pt-5 p-5 font-medium"
                            >
                                <label>{{ __('help_and_support') }}</label>
                            </header>

                            <div class="flex flex-col mt-2 h-5/6">
                                <label
                                    class="text-left line leading-5 text-xl font-bold mx-5"
                                >
                                    {{ __('how_can_we_help_you') }}
                                </label>

                                <label
                                    class="text-left line leading-5 mx-5 mt-8"
                                >
                                    {{ __('ask_a_question_to_seach_field') }}
                                </label>

                                <div
                                    class="flex flex-col space-y-6 m-5 justify-between h-full"
                                >
                                    <div>
                                        <div class="flex">
                                            <div
                                                class="flex flex-col flex-auto"
                                            >
                                                <div class="flex">
                                                    <div class="label" />
                                                </div>

                                                <div>
                                                    <div
                                                        class="relative mt-1 rounded-md shadow-sm"
                                                    >
                                                        <input
                                                            v-model="query"
                                                            type="text"
                                                            name="account-number"
                                                            class="block w-full border-1 rounded-md border-gray-300 focus:border-primary focus:ring-primary sm:text-sm"
                                                            :placeholder="
                                                                __(
                                                                    'how_to_set_up_kanzelidrive'
                                                                )
                                                            "
                                                        />
                                                        <div
                                                            class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
                                                        >
                                                            <SearchIcon
                                                                class="h-5 w-5 text-gray-400"
                                                                aria-hidden="true"
                                                            />
                                                        </div>
                                                    </div>

                                                    <ul
                                                        class="text-gray-800 h-full mt-5"
                                                    >
                                                        <span
                                                            v-if="
                                                                results.items
                                                                    .length ===
                                                                    0 &&
                                                                query.length >
                                                                    0 &&
                                                                fetchError ===
                                                                    false
                                                            "
                                                        >
                                                            {{
                                                                __(
                                                                    'no_solutions_found'
                                                                )
                                                            }}
                                                        </span>
                                                        <jet-input-error
                                                            v-if="
                                                                fetchError ===
                                                                true
                                                            "
                                                            :message="
                                                                __(
                                                                    'support_error_message'
                                                                )
                                                            "
                                                            class="mt-2"
                                                        />
                                                        <template
                                                            v-for="client in results.items"
                                                        >
                                                            <li
                                                                v-if="
                                                                    fetchError ===
                                                                    false
                                                                "
                                                            >
                                                                <a
                                                                    :href="
                                                                        client.link
                                                                    "
                                                                    target="_blank"
                                                                >
                                                                    <div
                                                                        class="group cursor-pointer font-semibold hover:bg-gray-200 flex cursor-default items-center rounded-md mx-2 px-2 py-2"
                                                                    >
                                                                        <div
                                                                            class="px-2 flex-auto"
                                                                        >
                                                                            <p
                                                                                class="font-bold"
                                                                            >
                                                                                {{
                                                                                    client
                                                                                        .title
                                                                                        .rendered
                                                                                }}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </a>
                                                            </li>
                                                        </template>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="flex flex-col"
                                        style="margin-bottom: -65px"
                                    >
                                        <label
                                            class="text-left mb-4 font-semibold"
                                            >{{ __('other_options') }} :
                                        </label>
                                        <div
                                            class="grid grid-cols-1 gap-0 mt-2 md:grid-cols-2"
                                        >
                                            <a
                                                href="https://hilfe.kanzleidrive.de"
                                                target="_blank"
                                                class="mb-2 black-link"
                                            >
                                                <div
                                                    class="flex iconlabel space-x-3"
                                                >
                                                    <LightBulbIcon
                                                        class="w-6 h-6"
                                                    />
                                                    <label
                                                        class="cursor-pointer hover:text-green underline text-left"
                                                    >
                                                        {{ __('help_center') }}
                                                    </label>
                                                </div>
                                            </a>
                                            <div
                                                class="flex iconlabel space-x-3"
                                            >
                                                <VideoCameraIcon
                                                    class="w-6 h-6"
                                                />
                                                <a
                                                    href="https://youtube.de/@KanzleiDrive"
                                                >
                                                    <label
                                                        class="cursor-pointer hover:text-green underline text-left"
                                                    >
                                                        Tutorials
                                                    </label>
                                                </a>
                                            </div>
                                            <a
                                                href="https://changelog.kanzleidrive.de/"
                                                target="_blank"
                                                class="mb-2 black-link"
                                            >
                                                <div
                                                    class="flex iconlabel space-x-3"
                                                >
                                                    <RefreshIcon
                                                        class="w-6 h-6"
                                                    />
                                                    <label
                                                        class="cursor-pointer hover:text-green underline text-left"
                                                    >
                                                        Changelog
                                                    </label>
                                                </div>
                                            </a>
                                            <div
                                                class="flex iconlabel space-x-3"
                                                @click="goToFormPage()"
                                            >
                                                <ChatAltIcon class="w-6 h-6" />
                                                <label
                                                    class="cursor-pointer hover:text-green underline text-left"
                                                >
                                                    Feedback-Formular
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!--Feedback Form-->
                        <div v-if="formPage">
                            <header
                                class="flex justify-between pt-5 p-5 font-bold text-xl"
                            >
                                <label>KanzleiDrive Feedback</label>
                            </header>

                            <div class="flex w-full p-5">
                                <div
                                    class="flex p-5 pr-1"
                                    style="background-color: #f2f2f2"
                                >
                                    <InformationCircleIcon class="w-6" />
                                </div>
                                <p
                                    class="w-full p-2 pr-5 text-sm"
                                    style="background-color: #f2f2f2"
                                >
                                    {{ __('support_header_message') }}
                                </p>
                            </div>

                            <form @submit.prevent="supportMessage()">
                                <div class="col-span-6 sm:col-span-4 p-5">
                                    <!--Zusammenfassung-->
                                    <label class="flex items-center">
                                        <jet-label
                                            for="summary"
                                            :value="__('summary')"
                                            class="text-base font-bold text-black"
                                        />
                                        <span class="flex ml-1 text-red-500"
                                            >*</span
                                        >
                                    </label>

                                    <jet-input
                                        v-model="form.summary"
                                        type="text"
                                        class="mt-1 block w-full mb-5"
                                        autocomplete="zusammenfassung"
                                    />

                                    <jet-input-error
                                        :message="form.errors.summary"
                                        class="mb-5"
                                    />

                                    <!--Beschreibung-->
                                    <label class="flex items-center mb-2">
                                        <jet-label
                                            for="description"
                                            :value="__('support_description')"
                                            class="text-base font-bold text-black"
                                        />
                                        <span class="flex ml-1 text-red-500"
                                            >*</span
                                        >
                                    </label>
                                    <div
                                        class="flex w-full items-center mb-3"
                                        style="background-color: #f2f2f2"
                                    >
                                        <div class="flex p-5 pr-1">
                                            <InformationCircleIcon
                                                class="w-6"
                                            />
                                        </div>
                                        <p class="w-full p-2 pr-5 text-sm">
                                            {{
                                                __(
                                                    'support_description_message'
                                                )
                                            }}
                                        </p>
                                    </div>

                                    <textarea
                                        id="description"
                                        v-model="form.description"
                                        type="text"
                                        class="border-gray-300 focus:border-primary focus:ring focus:ring-light-primary focus:ring-opacity-50 rounded-md shadow-sm mt-1 block w-full mb-5"
                                        autocomplete="name"
                                    />

                                    <jet-input-error
                                        :message="form.errors.description"
                                        class="mb-5"
                                    />

                                    <div
                                        class="flex w-full my-3 items-center"
                                        style="background-color: #f2f2f2"
                                    >
                                        <div class="flex p-5 pr-1">
                                            <InformationCircleIcon
                                                class="w-6"
                                            />
                                        </div>
                                        <p class="w-full p-2 pr-5 text-sm">
                                            {{
                                                __('support_fileUpload_message')
                                            }}
                                        </p>
                                    </div>

                                    <!--Datei(en)-->

                                    <jet-input
                                        v-show="false"
                                        id="files"
                                        type="file"
                                        autocomplete="name"
                                        accept="image/*"
                                        multiple
                                        @input="form.uploadFiles = files"
                                        @change="pseudoFiles"
                                    />

                                    <!-- fake Datei(en) -->
                                    <label class="flex items-center mb-2">
                                        <jet-label
                                            for="uploadFiles"
                                            :value="__('file(s)')"
                                            class="text-base font-bold text-black"
                                        />
                                    </label>
                                    <div
                                        id="pseudoDateien"
                                        class="border-gray-300 focus:border-primary focus:ring focus:ring-light-primary focus:ring-opacity-50 rounded-md shadow-sm mt-1 block w-full mb-5"
                                        style="
                                            padding-top: 0.5rem;
                                            padding-right: 0.75rem;
                                            padding-bottom: 0.5rem;
                                            padding-left: 0.75rem;
                                            border-width: 1px;
                                            min-height: 42px;
                                        "
                                    >
                                        <div
                                            class="large-12 medium-12 small-12 cell"
                                        >
                                            <div
                                                v-for="(file, key) in files"
                                                class="flex"
                                            >
                                                {{ file.name }}
                                                <span
                                                    class="pl-2 flex justify-center items-center text-red-600"
                                                    @click="removeFile(key)"
                                                >
                                                    <trash-icon
                                                        class="h-5 w-5 cursor-pointer"
                                                    />
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <PaperClipIcon
                                        class="w-6 float-right text-primary cursor-pointer"
                                        style="
                                            position: relative;
                                            bottom: 51px;
                                            right: 15px;
                                        "
                                        @click="pseudoFilesClick"
                                    />

                                    <jet-input-error
                                        v-for="error in Object.keys(
                                            form.errors
                                        ).filter((k) =>
                                            k.startsWith('uploadFiles')
                                        )"
                                        :message="form.errors[error]"
                                        class="mb-5"
                                    />

                                    <!--Name-->
                                    <label class="flex items-center mb-2">
                                        <jet-label
                                            for="name"
                                            :value="__('name')"
                                            class="text-base font-bold text-black"
                                        />
                                        <span class="flex ml-1 text-red-500"
                                            >*</span
                                        >
                                    </label>
                                    <jet-input
                                        id="name"
                                        v-model="form.name"
                                        type="text"
                                        class="mt-1 block w-full mb-5"
                                        autocomplete="name"
                                    />

                                    <jet-input-error
                                        :message="form.errors.name"
                                        class="mb-5"
                                    />

                                    <!--E-mail-->
                                    <label class="flex items-center mb-2">
                                        <jet-label
                                            for="email"
                                            :value="__('email')"
                                            class="text-base font-bold text-black"
                                        />
                                    </label>

                                    <jet-input
                                        id="email"
                                        v-model="form.email"
                                        type="email"
                                        class="mt-1 block w-full mb-5"
                                        autocomplete="name"
                                    />

                                    <jet-input-error
                                        :message="form.errors.email"
                                        class="mb-5"
                                    />

                                    <!--Telefonnummer-->
                                    <jet-label
                                        for="phoneNumber"
                                        :value="__('register_data_phone_label')"
                                        class="text-base font-bold text-black"
                                    />
                                    <jet-input
                                        id="phoneNumber"
                                        v-model="form.phoneNumber"
                                        type="text"
                                        class="mt-1 block w-full mb-5"
                                        autocomplete="name"
                                    />

                                    <jet-input-error
                                        :message="form.errors.phoneNumber"
                                        class="mb-5"
                                    />

                                    <!-- AGB -->
                                    <div class="flex items-start mb-5">
                                        <input
                                            type="checkbox"
                                            name="agb"
                                            checked
                                            required
                                        />
                                        <label
                                            for="agb"
                                            class="w-full pl-5 text-sm"
                                        >
                                            {{ __('support_agb') }}
                                        </label>
                                    </div>

                                    <!-- Buttons -->
                                    <div class="mb-16">
                                        <button
                                            class="bg-transparent border border-primary rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-bold text-primary hover:bg-dark-primary hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary font-normal float-left w-2/5"
                                            @click="goToHomePage()"
                                        >
                                            {{ __('cancel') }}
                                        </button>
                                        <jet-button
                                            class="font-normal float-right w-2/5"
                                        >
                                            {{ __('send') }}
                                        </jet-button>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <!-- Email Success -->
                        <div v-if="formSuccess" class="pt-16 text-center">
                            <CheckCircleIcon
                                class="w-20 text-primary m-auto d-block"
                            />
                            <header class="pt-5 p-5 text-2xl font-bold">
                                <label>
                                    {{ __('thank_you_for_contacting_us') }}
                                </label>
                            </header>
                            <p>
                                {{ __('we_will_be_in_touch') }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Transition>
</template>

<script setup>
    import { XIcon, SearchIcon, CheckCircleIcon } from '@heroicons/vue/solid';
    import {
        LightBulbIcon,
        RefreshIcon,
        ChatAltIcon,
        InformationCircleIcon,
        PaperClipIcon,
        VideoCameraIcon,
        TrashIcon,
    } from '@heroicons/vue/outline';
    import { reactive, ref, watch } from 'vue';
    import axios from '@/global/axios.js';
    import debounce from 'debounce';
    import JetInputError from '@/Jetstream/InputError.vue';
    import JetLabel from '@/Jetstream/Label.vue';
    import JetInput from '@/Jetstream/Input.vue';
    import JetButton from '@/Jetstream/Button.vue';
    import { useForm } from '@inertiajs/vue3';

    defineProps({
        show: Boolean,
    });

    const emit = defineEmits(['close']);
    let formHome = ref(true);
    let formPage = ref(false);
    let formSuccess = ref(false);
    const query = ref('');
    const results = reactive({ items: [] });
    let fetchError = ref(false);
    let files = reactive([]);

    const goToFormPage = () => {
        formHome.value = false;
        formPage.value = true;
    };

    const goToHomePage = () => {
        formHome.value = true;
        formPage.value = false;
    };

    const closeSupportModal = () => {
        emit('close');
        formHome.value = true;
        formPage.value = false;
        formSuccess.value = false;
    };

    const pseudoFiles = (event) => {
        let uploadedFiles = event.target.files;

        for (let i = 0; i < uploadedFiles.length; i++) {
            files.push(uploadedFiles[i]);
        }
    };

    const removeFile = (key) => {
        files.splice(key, 1);
    };

    const pseudoFilesClick = () => {
        document.querySelector('#files').click();
    };

    watch(
        query,
        debounce(async (newQuery) => {
            if (newQuery.length > 0) {
                try {
                    const response = await axios.get(
                        'https://hilfe.kanzleidrive.de/wp-json/wp/v2/faq',
                        {
                            params: { search: newQuery },
                        }
                    );
                    results.items = response.data;
                } catch (e) {
                    fetchError.value = true;
                }
            } else {
                results.items = [];
            }
        }, 400)
    );

    navigator.browser = (function () {
        const ua = navigator.userAgent;
        let tem;
        let M =
            ua.match(
                /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
            ) || [];
        if (/trident/i.test(M[1])) {
            tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
            return 'IE ' + (tem[1] || '');
        }
        if (M[1] === 'Chrome') {
            tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
            if (tem != null)
                return tem.slice(1).join(' ').replace('OPR', 'Opera');
        }
        M = M[2]
            ? [M[1], M[2]]
            : [navigator.appName, navigator.appVersion, '-?'];
        if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
        return M.join(' Version:');
    })();

    const getOS = () => {
        let userAgent = window.navigator.userAgent,
            platform =
                window.navigator?.userAgentData?.platform ||
                window.navigator.platform,
            macosPlatforms = [
                'Macintosh',
                'MacIntel',
                'MacPPC',
                'Mac68K',
                'macOS',
            ],
            windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
            iosPlatforms = ['iPhone', 'iPad', 'iPod'],
            os = 'other';

        if (macosPlatforms.indexOf(platform) !== -1) {
            os = 'Mac OS';
        } else if (iosPlatforms.indexOf(platform) !== -1) {
            os = 'iOS';
        } else if (windowsPlatforms.indexOf(platform) !== -1) {
            os = 'Windows';
        } else if (/Android/.test(userAgent)) {
            os = 'Android';
        } else if (/Linux/.test(platform)) {
            os = 'Linux';
        }
        return os;
    };

    const form = useForm({
        summary: null,
        description: null,
        uploadFiles: null,
        name: null,
        email: null,
        phoneNumber: null,
        browserInfo: navigator.browser,
        url: window.location.href,
        windowDimension:
            'Höhe: ' + screen.height + 'px / Breite: ' + screen.width + 'px',
        os: getOS(),
    });

    const supportMessage = () => {
        form.post(route('support'), {
            preserveState: true,
            onSuccess: () => {
                form.reset();
                files.splice(0);
                formHome.value = false;
                formPage.value = false;
                formSuccess.value = true;
            },
        });
    };
</script>
