import { createApp, h, watch } from 'vue';
import { createInertiaApp, usePage, router } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import 'dayjs/locale/de.js';
import BaseLayout from '@/Layouts/BaseLayout.vue';
import * as Sentry from '@sentry/vue';
import fileStore from '@/Layouts/Components/Clients/Client/ClientFiles/fileStore.js';
import folderStore from '@/Layouts/Components/Clients/Client/ClientFiles/folderStore.js';

const translate = (key, replace = {}) => {
    let translation = usePage().props.language?.hasOwnProperty(key)
        ? usePage().props.language[key]
        : key;

    Object.keys(replace).forEach(
        (key) => (translation = translation.replace(':' + key, replace[key]))
    );

    return translation;
};

window.__ = translate;

router.on('navigate', (event) => {
    const defaultPrimaryColors = ['#007bfc', '#006fe3', '#e6f2ff'];
    const primaryColors =
        event.detail.page.props.auth_type === 'client_user' ||
        ((route().current('file-manager-share') ||
            route().current('file-manager-share.*') ||
            route().current('signatures.*')) &&
            event.detail.page.props.primary)
            ? (event.detail.page.props.primary ?? defaultPrimaryColors)
            : defaultPrimaryColors;

    document.documentElement.style.setProperty('--primary', primaryColors[0]);
    document.documentElement.style.setProperty(
        '--dark-primary',
        primaryColors[1]
    );
    document.documentElement.style.setProperty(
        '--light-primary',
        primaryColors[2]
    );

    Sentry.setContext('user', {
        am_id: usePage().props.auth_model?.id,
        am_type: usePage().props.auth_type,
        c_id: usePage().props.client?.id || null,
    });

    const currentRoute = route().current();

    if (
        fileStore.state.resetStateRoutes.includes(currentRoute) &&
        currentRoute !== fileStore.state.lastRoute
    ) {
        fileStore.state.checkedFiles = [];
        folderStore.state.checkedFolders = [];
    }

    fileStore.state.lastRoute = currentRoute;
});

createInertiaApp({
    title: (title) => `${title} - KanzleiDrive`,
    resolve: async (name) => {
        const resolvedPageComponent = await resolvePageComponent(
            `./Pages/${name}.vue`,
            import.meta.glob('./Pages/**/*.vue')
        );

        const pageLayout = resolvedPageComponent.default.layout;

        if (resolvedPageComponent.default?.__baseLayout) {
            return resolvedPageComponent;
        }

        resolvedPageComponent.default.__baseLayout = true;
        resolvedPageComponent.default.layout = (h, page) =>
            h(
                BaseLayout,
                pageLayout === undefined
                    ? () => page
                    : typeof pageLayout === 'function'
                      ? () => pageLayout(h, page)
                      : () => h(pageLayout, () => page)
            );

        return resolvedPageComponent;
    },
    setup({ el, App, props, plugin }) {
        const vueApp = createApp({
            setup() {
                dayjs.locale(document.documentElement.lang);

                dayjs.extend(localeData);
                dayjs.extend(isSameOrBefore);

                watch(
                    () => props.initialPage.props.locale,
                    (locale) => {
                        dayjs.extend(localeData);
                        dayjs.extend(isSameOrBefore);
                        dayjs.locale(locale);
                    }
                );
            },
            render: () => h(App, props),
        })
            .use(plugin)
            .mixin({
                methods: {
                    route,
                    __: translate,
                },
            })
            .provide('__', translate);

        if (import.meta.env.VITE_SENTRY_DSN?.length > 0) {
            Sentry.init({
                app: vueApp,
                dsn: import.meta.env.VITE_SENTRY_DSN,
                environment: import.meta.env.VITE_APP_ENV,
                integrations: [Sentry.replayIntegration()],
                tracesSampleRate: 0,
                logErrors: true,
                trackComponents: true,
                autoSessionTracking: false,
                sendClientReports: false,
                replaysSessionSampleRate: 0.01,
                replaysOnErrorSampleRate: 1.0,
            });
        }

        vueApp.mount(el);

        return vueApp;
    },
    progress: {
        color: '#374151',
        includeCSS: true,
        showSpinner: true,
    },
});
