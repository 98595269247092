import { reactive } from 'vue';

const state = reactive({
    checkedFiles: [],
    loadingDMSPush: false,
    loadingMyTaxPush: false,
    lastRoute: null,
    resetStateRoutes: [
        'client.file-manager.folders.show',
        'client.file-manager.recent-files.index',
        'client.file-manager.trash.folders.index',
    ],
});

export default {
    state,
};
