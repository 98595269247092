<template>
    <div>
        <slot />
    </div>

    <ToastList />

    <button
        class="fixed left-full top-2/4 rotate-90 origin-top-left whitespace-nowrap bg-primary text-white px-5 py-2 rounded-br-lg rounded-bl-lg font-bold text-xs md:text-sm"
        @click="showSupportModal = true"
    >
        Support
    </button>

    <SupportModal :show="showSupportModal" @close="showSupportModal = false" />
</template>

<script setup>
    import ToastList from '@/components/ToastList.vue';
    import { ref } from 'vue';
    import SupportModal from '@/Layouts/Components/SupportModal.vue';

    const showSupportModal = ref(false);
</script>
