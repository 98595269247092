<template>
    <button
        :type="type"
        class="bg-primary border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center items-center text-sm font-bold text-white hover:bg-dark-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
    >
        <slot />
    </button>
</template>

<script>
    import { defineComponent } from 'vue';

    export default defineComponent({
        props: {
            type: {
                type: String,
                default: 'submit',
            },
        },
    });
</script>
