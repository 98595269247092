<template>
    <TransitionGroup
        tag="div"
        enter-from-class="translate-x-full opacity-0"
        enter-active-class="duration-500"
        leave-active-class="duration-500"
        leave-to-class="translate-x-full opacity-0"
        class="fixed top-4 right-4 z-[100] w-full max-w-xs space-y-4"
    >
        <ToastListItem
            v-for="(item, index) in toast.items"
            :key="item.key"
            :message="item.message"
            :type="item.type"
            :duration="item.message.length > 70 ? 7500 : 5000"
            @remove="remove(index)"
        />
    </TransitionGroup>
</template>

<script setup>
    import ToastListItem from '@/components/ToastListItem.vue';
    import { watch } from 'vue';
    import { usePage } from '@inertiajs/vue3';
    import toast from '@/Stores/toast';

    let disableToastMessage = false;

    window.addEventListener('popstate', function () {
        disableToastMessage = true;
    });

    watch(
        () => usePage().props.toast,
        (toastProps) => {
            if (disableToastMessage === false) {
                toast.addMultiple(toastProps);

                return;
            }

            disableToastMessage = false;
        },
        { immediate: true }
    );

    function remove(index) {
        toast.remove(index);
    }
</script>
