import { reactive } from 'vue';

const state = reactive({
    moveModalOpened: false,
    checkedFolders: [],
    folderToMoveTo: { id: '', name: '' },
    checkedDatevFolders: [],
    sharedModalOpened: false,
});

const methods = {
    toggleDatevFolders(folder) {
        if (state.checkedDatevFolders.includes(folder.id)) {
            state.checkedDatevFolders.splice(
                state.checkedDatevFolders.indexOf(folder.id),
                1
            );
            return;
        }

        if (folder.datev_folder_id) {
            state.checkedDatevFolders.push(folder.id);
        }
    },
};

export default {
    state,
    methods,
};
